<template>
  <v-dialog :value="value" class="dialog" persistent width="1300">
    <v-card color=#fafafa>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit - {{tpr.item.orderCode}} - {{tpr.adGroup.description}} - {{tpr.item.description}}</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row>
          <v-col cols="2">
            <v-text-field readonly background-color="#fff" label="Order Code" dense outlined :value="tpr.item.orderCode"
              autocomplete="off" />
          </v-col>
          <v-col col="2">
            <v-text-field readonly background-color="#fff" dense outlined label="Selection Flag"
              :value="(tpr.selectionFlag) ? tpr.item.selectionFlag : 'N/A'" autcomplete="off" />
          </v-col>
          <v-col cols="4">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.item.description"
              label="tpr Description" autcomplete="off" />
          </v-col>
          <v-col cols="1">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.item.pack" label="Pack"
              autcomplete="off" />
          </v-col>
          <v-col cols="1">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.item.Size" label="Size"
              autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined :value="tpr.item.upc" label="UPC" autcomplete="off" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.caseCost" label="Case Cost" @change="formatCurrencyInput('caseCost', tpr.caseCost)" autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.AMAP" label="AMAP" @change="formatCurrencyInput('AMAP', tpr.AMAP)" autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.EBA" label="EBA" @change="formatCurrencyInput('EBA', tpr.EBA)" autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.BB" label="TPR BB" @change="formatCurrencyInput('BB', tpr.BB)" autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.scanAllow" label="TPR Scan" @change="formatCurrencyInput('scanAllow', tpr.scanAllow)" autcomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.ePay" label="ePay TPR" @change="formatCurrencyInput('ePay', tpr.ePay)" autcomplete="off" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" style="max-width: 183px;">
            <v-text-field background-color="#fff" dense outlined label="Quantity" type="number" min="0"
              v-model="tpr.regSRPCount" autcomplete="off" />
          </v-col>
          <v-icon class="mb-6">mdi-at</v-icon>
          <v-col cols="2" class="srp">
            <v-text-field background-color="#fff" prefix="$" dense outlined type="number" min="0" step="0.01"
              v-model="tpr.regSRP" label="Reg SRP" @change="formatCurrencyInput('regSRP', tpr.regSRP)" autcomplete="off" />
          </v-col>
          <v-col cols="2" style="max-width: 183px;">
            <v-text-field background-color="#fff" dense outlined label="Quantity" type="number" min="0"
              v-model="tpr.specialSRPCount" autcomplete="off" />
          </v-col>
          <v-icon class="mb-6">mdi-at</v-icon>
          <v-col cols="2">
            <v-text-field class="price" background-color="#fff" prefix="$" dense outlined type="number" min="0"
              step="0.01" v-model="tpr.specialSRP" label="TPR Retail" @change="formatCurrencyInput('specialSRP', tpr.specialSRP)" autcomplete="off" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-autocomplete background-color="#fff" dense outlined label="Department" :items="departments"
              item-text="name" item-value="id" v-model="tpr.department.id" />
          </v-col>
          <v-col cols="5">
            <v-autocomplete background-color="#fff" dense outlined label="Manufacturer" :items="manufacturers"
              item-text="description" item-value="id" v-model="tpr.manufacturer.id" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="Begin Date - Sun" v-model="tpr.dealBeginDate"
              prepend-inner-icon="mdi-calendar" />
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="End Date - Sat" v-model="tpr.dealEndDate"
              prepend-inner-icon="mdi-calendar" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field readonly background-color="#fff" dense outlined label="Contract"
              :value="tpr.contract.name" />
          </v-col>
          <v-col cols="2">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.contract.id"
              label="Contract ID" />
          </v-col>
          <v-col cols="6">
            <v-text-field readonly background-color="#fff" dense outlined :value="tpr.comments" label="Comments"
              :messages="tpr.contract.internalComment" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <em>
              <h3 style="color:#0c67a5;">{{tpr.adGroup.description}}</h3>
            </em>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-col cols="4" align="right">
          <v-btn height="40" color="#D32F2F" class="white--text" @click="confirmDelete=true">
            Delete<v-icon right dense>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn height="40" color="#00B24A" class="white--text ml-2" @click="updateTPR(tpr)">
            Update<v-icon right>mdi-update</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
    </v-card>

    <ConfirmDialog :value.sync="confirmDialog" :type="'TPR'" :remove="removeTPR"/>

  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'TprEdit',

  mixins: [notification],

  props: ['value', 'selectedTPR', 'getTPRs'],

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      confirmDialog: false,
      departments: [],
      manufacturers: []
    }
  },

  computed: {
    tpr () {
      return cloneDeep(this.selectedTPR)
    },

    formattedBeginDate () {
      return this.tpr.dealBeginDate ? this.moment(this.tpr.dealBeginDate).format(this.$config.date_format) : ''
    },

    formattedEndDate () {
      return this.tpr.dealEndDate ? this.moment(this.tpr.dealEndDate).format(this.$config.date_format) : ''
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getDepartments()
          this.getManufacturers()
          this.tpr.dealBeginDate = this.moment(this.tpr.dealBeginDate).format(this.$config.date_display)
          this.tpr.dealEndDate = this.moment(this.tpr.dealEndDate).format(this.$config.date_display)
          const values = {
            caseCost: this.tpr.caseCost,
            AMAP: this.tpr.AMAP,
            EBA: this.tpr.EBA,
            BB: this.tpr.BB,
            scanAllow: this.tpr.scanAllow,
            ePay: this.tpr.ePay,
            regSRP: this.tpr.regSRP,
            specialSRP: this.tpr.specialSRP,
            specialSRP2: this.tpr.specialSRP2
          }
          for (const key in values) {
            this.formatCurrencyInput(key, values[key])
          }
        }
      }
    }
  },

  methods: {
    formatCurrency (value) { return '$' + parseFloat(value).toFixed(2) },

    formatCurrencyInput (key, value) {
      this.tpr[key] = parseFloat(value).toFixed(2)
      this.$forceUpdate()
    },

    allowedTPRBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    allowedTPREnd: val => ![0, 1, 2, 3, 4, 6].includes(new Date(val).getDay()),

    getDepartments () {
      this.$contracts.getDepartments(this.tpr.supplier.id)
        .then(response => {
          this.departments = response
        })
    },
    getManufacturers () {
      this.$contracts.getManufacturers()
        .then(response => {
          this.manufacturers = response
        })
    },

    calculateSRP () {
      var srp = {
        adGroup: this.tpr.adGroup.id,
        AMAP: this.tpr.AMAP,
        BB: this.tpr.BB,
        ePay: this.tpr.ePay,
        pack: this.tpr.item.pack,
        regSRPCount: this.tpr.regSRPCount,
        regSRP: this.tpr.regSRP,
        scanAllow: this.tpr.scanAllow
      }
      this.$tprs.calculateSRP(srp)
        .then(response => {
          this.tpr.specialSRPCount = response.srpCount
          this.tpr.specialSRP = response.srp
        })
    },

    async removeTPR () {
      this.tpr.dealBeginDate = this.formattedBeginDate
      this.tpr.dealEndDate = this.formattedEndDate
      await this.$tprs.removeTPR(this.tpr)
        .then(() => {
          this.notify('success', 'TPR Successfully Deleted')
          this.$emit('update:value', false)
        }).catch(err => {
          try {
            this.notify('error', `${this.tpr.item.orderCode} - (${this.tpr.adGroup.description}) - ${err.response.data.failure_reason}`)
          } catch {
            this.notify('error', `${this.tpr.item.orderCode} Not Deleted`)
          }
        })
      this.getTPRs()
    },

    async updateTPR () {
      this.tpr.dealBeginDate = this.formattedBeginDate
      this.tpr.dealEndDate = this.formattedEndDate
      await this.$tprs.updateTPR(this.tpr)
        .then(() => {
          this.notify('success', 'TPR Updated')
          this.$emit('update:value', false)
        }).catch(err => {
          try {
            this.notify('error', `${err.response.data.failure_reason}`)
          } catch {
            this.notify('error', 'TPR Not Updated')
          }
        })
      this.getTPRs()
    }
  }
}
</script>
